<template>
  <div>
      <v-container>
        <v-form ref="form" lazy-validation>
          <v-text-field
            v-model="project.name"
            label="Project Name"
            required
            :rules="[v => !!v || 'Name is required']"
          ></v-text-field>

          <v-select
            :items="tools"
            v-model="project.survey_tool"
            label="Survey Tool"
            required
            :rules="[v => !!v || 'Survey Tool is required']"
          ></v-select>

          <v-text-field
            v-model="project.survey_id"
            label="Survey ID"
            required
            :rules="[v => !!v || 'Survey Id is required']"
          ></v-text-field>

          <v-text-field
            v-model="project.survey_url"
            label="Survey URL"
            :rules="urlRules"
          ></v-text-field>
        </v-form>
        <v-btn color="primary mr-2" @click="createProject">Create Project</v-btn>
      </v-container>

  </div>
</template>

<script>
import RestResource from "../../services/dataServiceBuyer"
const service = new RestResource();

export default {
  name: "project",
  props: {
    type: String,
    project: Object
  },
  data() {
    return {
      tools: [
        { text: "SurveyGizmo", value: "SG" },
        { text: "InsightzClub", value: "IZC" },
      ],
      urlRules: [
        v => !!v || "Url must be valid",
        // eslint-disable-next-line
        v => /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(v) || "Url must be valid"
      ],
    };
  },

  methods: {
    createProject() {
      if (this.$refs.form.validate()) {
        this.$setLoader();
        service.createProject(this.project).then(() => {
          this.$disableLoader();
          alert("Succesfully Created Project");
        }).catch(e => {
          alert(`Error${e}`);
          this.$disableLoader()
        });
      }
    }
  }
};
</script>