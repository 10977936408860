<template>
  <v-card>
    <v-card-title>Create Project</v-card-title>
    <v-container>
      <ProjectForm type="CREATE" :project="project" />
    </v-container>
  </v-card>
</template>

<script>
import ProjectForm from "./form";
export default {
  components: {
    ProjectForm
  },
  data() {
    return {
      project: {
        valueLabel: "PT"
      }
    }
  }
};
</script>